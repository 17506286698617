const strings = {
  loginPage: {
    headTitle: 'Prisijungti',
    h1String: 'Prisijungti',
    emailPlaceHolder: 'Įveskite el. paštą',
    formSubmitButtonText: 'Prisijungti',
    passwordPlaceHolder: 'Įveskite slaptažodį',
  },
  formErrors: {
    requiredField: 'Būtinas laukas',
    entarValidEmail: 'Įveskite taisyklingą el. paštą',
    checkAllFields: 'Patikrinkite visus būtinus laukus',
    email_dont_exists: 'Toks el. paštas neegzistuoja!',
    passwordsDontMatch: 'Slaptažodžiai nesutampa',
    passwords_dont_match: 'Slaptažodžiai nesutampa',
    no_user_with_this_hash: 'Toks slaptažodžio keitimo url neegzistuoja!',
    bad_credentials: 'Blogi prisijungimo duomenys',
    cannotBeEqualOrBelowZero: 'Negali buti mazesnis arba lygus 0',
    discountIsGreaterThanTotal: 'Nuolaida didesne uz Viso suma',
    email_is_already_taken: 'El. paštas egzistuoja',
  },
  sideMenu: {
    gerneralRequests: 'Bendros užklausos',
    clients: 'Klientai',
    estimatesOfWorks: 'Sąmatos',
    invoices: 'Sąskaitos',
    users: 'Vartotojai',
    myPart: 'Mano skiltis',
    clientTags: 'Klientų žymos',
    defaults: 'Nustatymai',
  },
  userProfileEditForm: {
    name: 'Vardas',
    surname: 'Pavardė',
    email: 'El. paštas',
    phone: 'Telefono numeris',
    updateBtn: 'Išsaugoti',
    updateProfile: 'Koreguoti vartotojo informaciją',
    settlement: 'Miestas',
    street: 'Gatvė',
    propertyNumber: 'Namo numeris',
    filterLabel: 'Filtras pagal žymą',
    device: 'Įrenginys',
    additionalDevice: 'Papildomi įrenginiai / ištekėjimas',
    comment: 'Komentaras',
    devicePrice: 'Įrenginio kaina',
    advancePayment: 'Avansas',
    pipelinePrice: 'Vamzdyno metro kaina',
    completionDate: 'Atlikimo data',
    grandTotalPrice: 'Galutinė suma',
  },
  back: {
    backButton: 'Grįžti',
  },
  addUser: {
    addNewUser: 'Pridėti naują vartotoją',
  },
  deleteUserPopup: {
    sureForDeletionUser: 'Ar tikrai norite ištrinti vartotoją?',
    deleteUser: 'Ištrinti',
    abortDeletion: 'Atšaukti',
  },
  eowPages: {
    title: 'Sąmatos',
    createNew: 'Pridėti sąmatą',
    newEow: 'Nauja sąmata',
    clientFields: 'Kliento duomenys',
    client_full_name: 'Vardas / Pavardė',
    client_phone: 'Telefonas',
    client_email: 'El. paštas',
    property_settlement: 'Gyvenvietė / miestas',
    property_street: 'Gatvė',
    property_nr: 'Namo / Sklypo nr.',
    selectClient: 'Pasirinkti klienta',
    empty: '-',
    device_model: 'Modelis',
    device_population: 'Gyventojų skaičius',
    device_performance: 'Našumas',
    device_cover: 'Dangtis',
    device_efficiency: 'Išvalymo efektyvumas',
    itemTitle: 'Pavadinimas',
    itemQty: 'Kiekis',
    itemUnit: 'Matas',
    itemUnitPrice: 'Vieneto kaina',
    itemTotalPrice: 'Kaina EUR',
    price_included: 'įskaičiuota',
    comments: 'Pastabos',
    total: 'Viso',
    discount: 'Nuolaida',
    grand_total: 'IŠ VISO',
    selectStatus: 'Statusas',
  },
  archiveClientPopup: {
    sureArchiveClient: 'Ar tikrai norite archyvuoti?',
    archiveClient: 'Archyvuoti',
    abortDeletion: 'Atšaukti',
  },
  webSubmissions: {
    selectStatus: 'Pasirinkite statusą',
  },
  asignMeModal: {
    sureAssignMe: 'Ar tikrai norite priskirti užklausą sau?',
    assign: 'Priskirti',
    decline: 'Atšaukti',
  },
  createClientModal: {
    crateClient: 'Sukurti',
    decline: 'Atšaukti',
    clientCreation: 'Sukurti klientą',
  },
  recallModal: {
    decline: 'Atšaukti',
    save: 'Išsaugoti',
  },
  clientLabelsPage: {
    title: 'Klientų žymos',
  },
  clientPrifileSub: {
    selectStatus: 'Pasirinkite statusą',
    comment: 'Komentaras',
    writeComment: 'Įrašykite komentarą',
    createSubmission: 'Sukurti užklausą',
  },
  createMyClientForm: {
    device: 'Įrenginys',
    additionalDevice: 'Papildomi įrenginiai / ištekėjimas',
    comment: 'Komentaras',
    devicePrice: 'Įrenginio kaina',
    advancePayment: 'Avansas',
    pipelinePrice: 'Vamzdyno metro kaina',
    completionDate: 'Atlikimo data',
    grandTotalPrice: 'Galutinė suma',
    companyName: 'Įmonės pavadinimas',
    companyVat: 'Įmonės PVM kodas',
    companyCode: 'Įmonės kodas',
    isCompany: 'Įmonė',
  },
  webSubmisionsStatuses: {
    new: 'Nauja',
    in_review: 'Peržiūroje',
    reviewed: 'Peržiūrėta',
    need_callback: 'Reikia perskambinimo',
  },
  clientsFilter: {
    filterByNameSurnameAndPhone: 'Filtras pagal vardą / pav. arba telefoną',
  },
};

export default strings;
