import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faPenToSquare, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import DeleteUserModal from '../../../../components/modals/deleteUser/DeleteUserModal';
import PaginationGolbal from '../../../../components/pagination/PaginationGlobal';
import cms from '../../../../app/api/cms';
import '../../eowpages/eowList/EowListPage.scss';
import StatusBadge from './StatusBadge';
import { Alert } from 'reactstrap';
import CreateInvoiceModal from '../../../../components/modals/createInvoice/CreateInvoiceModal';

const InvoicesListPage = () => {
    const statuses = useSelector((state) => state.invoices.statuses);
    const [rows, setRows] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        loadRows(currentPage);
    }, [currentPage]);

    const handleDeleteRow = async (rowId) => {
        await cms
            .DeleteInvoice(rowId)
            .then(function (response) {
                if (response.data.message === 'invoice_deleted') {
                    loadRows(currentPage);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const loadRows = async (currentPageIndex) => {
        setRows([]);

        await cms
            .GetInvoices(currentPageIndex)
            .then(function (response) {
                console.log('res->', response);
                if (response.data.data !== undefined) {
                    setRows(response.data.data.rows);
                    setTotalItemsCount(response.data.data.total_count);
                }
            })
            .catch(function (error) {});
    };

    const handlePageChange = (newActivePage) => {
        setCurrentPage(newActivePage);
    };

    function StatusHtml(elStatus) {
        if (statuses && statuses.length > 0) {
            let findStatus = statuses.find((el) => el.value === elStatus);
            if (findStatus !== -1) {
                return <StatusBadge status={elStatus} text={findStatus.label} />;
            }
        }
    }

    return (
        <div className='users-page'>
            <PageTitle>Sąskaitos</PageTitle>
            <div className='table-box'>
                <div className='upper-side'>
                    <CreateInvoiceModal />
                </div>
                {rows && rows.length > 0 ? (
                    <>
                        <div className='table-all'>
                            <div className='table-row main'>
                                <div style={{ width: '10%' }}>
                                    <h6>ID</h6>
                                </div>
                                <div style={{ width: '30%' }}>
                                    <h6>Sąskaita / Klientas</h6>
                                </div>
                                <div style={{ width: '30%' }}>
                                    <h6>Statusas</h6>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <h6>Suma</h6>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <h6>Veiksmai</h6>
                                </div>
                            </div>
                            {rows.map((row) => (
                                <div className='table-row' key={`${row.id}-rows`}>
                                    <div style={{ width: '10%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>ID</h6>
                                        <p>{row.id}</p>
                                    </div>
                                    <div style={{ width: '30%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>SĄSKAITA / KLIENTAS</h6>
                                        <p>
                                            NR. {row.identifier_prefix}-{row.identifier}
                                            <br />
                                            {row.buyer_name}
                                        </p>
                                    </div>
                                    <div style={{ width: '30%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>STATUSAS</h6>
                                        <p>{StatusHtml(row.status)}</p>
                                    </div>
                                    <div style={{ width: '20%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>SUMA</h6>
                                        <p>{row.grand_total} &euro;</p>
                                    </div>
                                    <div style={{ width: '10%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>VEIKSMAI</h6>
                                        <p className='icons-table mobile-small-icon'>
                                            <Link to={`/cms/admin/invoices/${row.id}/edit`}>
                                                <FontAwesomeIcon icon={faPenToSquare} className='mob-r' />
                                            </Link>
                                            {row.downloadLink ? (
                                                <a className='text-info' href={row.downloadLink} download>
                                                    <FontAwesomeIcon icon={faFileDownload} className='mob-r' />
                                                </a>
                                            ) : (
                                                ''
                                            )}
                                            <DeleteUserModal rowId={row.id} onSuccess={handleDeleteRow} textContent={`Ar tikrai norite ištrinti?`} title={`Sąskaitos #${row.id} trinimas`} />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='pagination-container'>
                            <PaginationGolbal activePage={currentPage} totalItemsCount={totalItemsCount} handlePageChange={handlePageChange} itemsCountPerPage={10} />
                        </div>
                    </>
                ) : (
                    <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                        Sąskaitų nėra.
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default InvoicesListPage;
