import axios from 'axios';
const mainApiUrl = process.env.REACT_APP_API_ROOT;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';

const auth = {
  GetUser,
  GetEows,
  GetEowStatuses,
  GetDefaultServices,
  GetBasicFields,
  GetClientsForSelect,
  CreateEow,
  GetEowData,
  UpdateEow,
  DeleteEow,
  GetClientLabels,
  CreateClientLabel,
  UpdateClientLabel,
  DeleteClientLabel,
  GetClientLabel,
  GetClientLabelsForSelect,
  CreateMyClient,
  GetClients,
  GetClient,
  AssignClientToAdmin,
  GetSubmissionStatuses,
  GetClientSubmissions,
  CreateClientSubmission,
  UpdateClient,
  GetInvoices,
  GetInvoice,
  CreateInvoice,
  UpdateInvoice,
  DeleteInvoice,
  DeleteUser,
  GetUsers,
  CreateUser,
  GetUserData,
  EditUser,
  GetWebSubmissions,
  GetDashboardData,
  EditUserProfile,
  GetWebSubmissionData,
  AssignToAdminWebSubmission,
  WebSubmissionNeedCallback,
  WebSubmissionChangeStatus,
  WebSubmissionCreateClient,
  ClientArchive,
  GetBasicDefaults,
  UpdateBaseConfigs,
  WebSubmissionDelete,
};
export default auth;

async function UpdateBaseConfigs(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/basic-configs/update`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function ClientArchive(rowID, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/clients/${rowID}/archive`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function WebSubmissionDelete(rowID) {
  let response = await axios.delete(`${mainApiUrl}/cms/admin/web-submissions/${rowID}/delete`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function WebSubmissionCreateClient(rowID, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/web-submissions/${rowID}/create-client`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function WebSubmissionChangeStatus(rowID, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/web-submissions/${rowID}/change-status`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function WebSubmissionNeedCallback(rowID, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/web-submissions/${rowID}/need-callback`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function AssignToAdminWebSubmission(rowID) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/web-submissions/${rowID}/assign-to-admin`, [], {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetWebSubmissionData(rowID) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/web-submissions/${rowID}/data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetWebSubmissions(pageIndex) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/web-submissions?page=${pageIndex}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetDashboardData() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/dashboard/stats`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function EditUser(fields, userID) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/users/${userID}/edit`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}
async function EditUserProfile(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/auth/update-current-user`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetUserData(rowID) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/users/${rowID}/data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetBasicDefaults() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/basic-configs`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function CreateUser(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/users/create`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetUsers(pageIndex) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/users?page=${pageIndex}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function DeleteUser(userId) {
  let response = await axios.delete(`${mainApiUrl}/cms/admin/users/${userId}/delete`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function DeleteInvoice(rowId) {
  let response = await axios.delete(`${mainApiUrl}/cms/admin/invoices/${rowId}/delete`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function UpdateInvoice(rowID, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/invoices/${rowID}/update`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function CreateInvoice(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/invoices/create`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetInvoice(rowID) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/invoices/${rowID}/data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetInvoices(pageIndex) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/invoices?page=${pageIndex}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function UpdateClient(rowID, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/my-clients/${rowID}/edit`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function CreateClientSubmission(rowID, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/clients/${rowID}/create-submission`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetClientSubmissions(clientID, pageIndex) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/clients/${clientID}/submissions?page=${pageIndex}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetSubmissionStatuses() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/web-submissions/statuses-list`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function AssignClientToAdmin(rowID) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/clients/${rowID}/assign-to-admi`, [], {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetClient(rowId) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/clients/${rowId}/data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetClients(pageIndex, queryPart) {
  let stringForSearch = `&searchString=${queryPart.searchString}&archive=${queryPart.archivated}`;
  let response = await axios.get(`${mainApiUrl}/cms/admin/clients?page=${pageIndex}${stringForSearch}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function CreateMyClient(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/my-clients/create`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetClientLabelsForSelect() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/client-labels/for-select`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetClientLabel(rowId) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/client-labels/${rowId}/data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function DeleteClientLabel(rowId) {
  let response = await axios.delete(`${mainApiUrl}/cms/admin/client-labels/${rowId}/delete`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function UpdateClientLabel(fields, rowId) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/client-labels/${rowId}/update`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function CreateClientLabel(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/client-labels/create`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetClientLabels() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/client-labels`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function DeleteEow(rowId) {
  let response = await axios.delete(`${mainApiUrl}/cms/admin/estimate-of-works/${rowId}/delete`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function UpdateEow(rowId, fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/estimate-of-works/${rowId}/update`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetEowData(rowId) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/estimate-of-works/${rowId}/data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function CreateEow(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/admin/estimate-of-works/create`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetClientsForSelect() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/clients/for-form`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetBasicFields() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/basic-configs`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetDefaultServices() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/services/defaults`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetEowStatuses() {
  let response = await axios.get(`${mainApiUrl}/cms/admin/estimate-of-works/statuses`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetEows(pageIndex, queryPart) {
  let response = await axios.get(`${mainApiUrl}/cms/admin/estimate-of-works?page=${pageIndex}${queryPart}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}

async function GetUser() {
  let response = await axios.get(`${mainApiUrl}/cms/auth/current-user-data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return response;
}
